import { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import SemanticTheme from "../../themes/SemanticTheme";
import React from "react";
import { GvJourneyStep, GvStepperTag } from "../../interfaces/giftvouchers";
import { FHStepper } from "../../components/SemanticTheme/FHStepper";
import { JourneyTypes } from "../../interfaces/stepper";
import { useNextRouterPathname } from "../../utils/common";
import { setActiveGvStepByUrlAction } from "@/store/actions";
import {
  useGvActiveStepSelector,
  useGvSettingsSelector,
  useActiveStepperPersistedSelector,
} from "@/store/selectors";

export type BookingJourneyStepperProps = {};

export const GVStepperComponent = () => {
  const history = useRouter();
  const dispatch = useDispatch();

  const { gvBookingSteps, activeGvStepIndex, errorMessage, loaded } =
    useGvSettingsSelector();
  const { activeJourneyStep: persistedActiveJourneyStep, activeJourney } =
    useActiveStepperPersistedSelector();

  const activeStep = useGvActiveStepSelector();

  useEffect(() => {
    const url = useNextRouterPathname(history);
    if (loaded && gvBookingSteps) {
      const stepperUrlIndex = gvBookingSteps.findIndex(
        (step: GvJourneyStep) => step?.url === url
      );
      if (
        activeJourney === JourneyTypes.gvJourney &&
        persistedActiveJourneyStep !== null &&
        persistedActiveJourneyStep > 0 &&
        stepperUrlIndex > persistedActiveJourneyStep
      ) {
        history.push(gvBookingSteps[persistedActiveJourneyStep]?.url || "");
      } else if (stepperUrlIndex > 0 && persistedActiveJourneyStep === 0) {
        history.push(gvBookingSteps[persistedActiveJourneyStep]?.url || "");
      }
      dispatch(setActiveGvStepByUrlAction(url));
    }
  }, [loaded, gvBookingSteps, persistedActiveJourneyStep]);

  const handleDisableSteps =
    activeStep?.tag === GvStepperTag.confirm ||
    activeGvStepIndex === (gvBookingSteps?.length && gvBookingSteps.length - 1);

  if (!(gvBookingSteps && loaded && !errorMessage)) {
    return null;
  }

  return (
    <ThemeProvider theme={SemanticTheme}>
      <FHStepper
        steps={gvBookingSteps}
        activeStepIndex={activeGvStepIndex}
        disableSteps={handleDisableSteps}
        onStepClick={(url) => {
          if (url) {
            history.push({
              pathname: url,
            });
          }
        }}
      />
    </ThemeProvider>
  );
};
